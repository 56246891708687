.campaign-picture-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1.072;
  background-color: #acacac;
  border-radius: 15px;
}

.campaign-picture-placeholder__text {
  text-align: center;
  color: "black";
  font-size: 11px;
  font-family: "Nunito Sans";
  font-weight: "400";
}
