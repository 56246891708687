.about {
    padding-top: 6px;
}
    .about__heading {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: var(--black);
        font-size: 24px;
        line-height: 32px;
    }

    .about__body {
        margin-top: 12px;
    }
        .about__section {
            margin-top: 18px;
        }

        .about__section:first-child {
            margin-top: 0;
        }
            .about__description {
                padding: 14px 12px 22px;
            }

            .about__social-media {
                display: flex;
                align-items: flex-start;
                justify-content: space-evenly;
                padding: 8px;
            }
