.auth {
  padding-bottom: 80px;
}
.auth__head {
  margin-bottom: 34px;
  padding: 16px;
  background-color: var(--brand);
  background-image: url("images/auth-background@3x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #ffffff;
}
.auth__logo {
  margin-top: 36px;
  vertical-align: top;
}

.auth__back {
  display: flex;
  align-items: center;
  width: 60px;
  height: 46px;
  margin-left: calc(-1 * var(--container-padding));
  padding-left: var(--container-padding);
}
.auth__chevron {
  vertical-align: top;
  fill: #ffffff;
}

.auth__wrap {
}
.auth__form {
}
.auth__fieldset {
  padding: 0;
  margin: 0;
  border: none;
}
.auth__main-heading {
  font-weight: var(--black);
  font-size: 36px;
  line-height: 42px;
}

.auth__main-note {
  margin-top: 17px;
  margin-bottom: 38px;
  font-size: 18px;
  line-height: 24px;
  color: #5d6164;
}

.auth__illustration {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.auth__heading {
  margin-top: 40px;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
}

.auth__note {
  margin-top: 12px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

.auth__panel {
  margin-top: 16px;
  padding: 20px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
}
.auth__field {
  margin-top: 16px;
}

.auth__field:first-child {
  margin-top: 0;
}

.auth__swap-method {
  display: block;
  width: fit-content;
  margin-left: auto;
  background-color: transparent;
  border: none;
  padding: 8px 0;
  font-family: inherit;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--brand);
  cursor: pointer;
}

.auth__swap-method + .auth__field {
  margin-top: 8px;
}

.auth__submit {
  margin-top: 35px;
}

.auth__alternation {
  margin-top: 26px;
  font-size: 14px;
  line-height: 20px;
  color: #5d6164;
  text-align: center;
}
.auth__alt-action {
  vertical-align: baseline;
  padding: 10px 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-weight: var(--extra-bold);
  font-size: 14px;
  line-height: 20px;
  color: var(--brand);
  cursor: pointer;
}

.auth__code-note {
}

.auth__code-hint {
  margin-top: 12px;
  font-weight: var(--light);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
