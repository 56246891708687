.split-input {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.split-input > div {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 14px;
}

/* Though it is not a usage of Input component, corresponding class ".input" used in here.
   Add overrides specific for this instances:
 */
.split-input .input {
  width: 36px;
  height: 44px;
  padding-left: 0;
  padding-right: 0;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}
