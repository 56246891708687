.pwa-header {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    right: 0;
    height: var(--header-height);
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
}
    .pwa-header__logo {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: var(--container-padding);
        padding-right: 16px;
    }
        .pwa-header__emblem {
            height: 32px;
            background-color: var(--image-fallback);
        }

    .pwa-header__brand-name {
        flex-grow: 1;
        font-weight: var(--extra-bold);
        font-size: 22px;
        line-height: 28px;
        color: #000000;
    }

    .pwa-header__scan-handler {
        margin-left: auto;
        border: none;
        background-color: transparent;
        height: 100%;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
        .pwa-header__scanner {
            vertical-align: top;
        }

    .pwa-header__profile {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        padding-right: var(--container-padding);
    }
        .pwa-header__user-pic {
            vertical-align: top;
            width: 32px;
            height: 32px;
            border-radius: 8px;
            background-color: var(--image-fallback);
        }
