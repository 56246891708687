.location {
    font-size: 12px;
    line-height: 14px;
    color: #5D6164;
}
    .location__icon {
        position: relative;
        top: 1px;
        margin-right: 2px;
        width: 12px;
        height: 12px;
        vertical-align: baseline; /* bottom peak of the marker should match baseline */
        fill: currentColor;
    }


@media (width < 1500px) {

    .location {
        font-size: 11px;
        line-height: 12px;
    }
        .location__icon {
            width: 10px;
            height: 10px;
        }
}



.location--large {
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}
    .location--large .location__icon {
        position: static;
        margin-top: -2px;
        margin-bottom: -4px;
        margin-right: 18px;
        width: 20px;
        height: 20px;
    }
