.timer {
    flex-shrink: 0;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    white-space: nowrap;
}
    .timer__icon {
        vertical-align: baseline; /* bottom peak of the marker should match baseline */
        position: relative;
        top: 1.5px;
        margin-right: 2px;
        width: 12px;
        height: 12px;
        fill: currentColor;
    }


@media (width < 1500px) {

    .timer {
        font-size: 11px;
        line-height: 12px;
    }
        .timer__icon {
            top: 1px;
            width: 10px;
            height: 10px;
        }
}
