.home {
}
.home__card {
}

.home__filter {
  margin-top: 6px;
}

.home__list {
}
.home__item {
  margin-top: 12px;
  display: block;
  text-decoration: none;
  color: inherit;
}

.home__item:first-child {
  margin-top: 0;
}
