.pwa-nav {
    position: fixed;
    z-index: 1000;
    left: 0;
    bottom: 0;
    right: 0;
    height: var(--nav-height);
    background-color: #FFFFFF;
    display: flex;
    align-items: stretch;
}
    .pwa-nav__link {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        flex-basis: 0;
        color: #B7BDC2;
        text-decoration: none;
        transition: color 100ms ease;
    }
        .pwa-nav__link::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 2px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 40px;
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            background-color: transparent;
            transition: background-color 100ms ease;
        }

        .pwa-nav__symbol {
            vertical-align: top;
            width: 28px;
            height: 28px;
            fill: currentColor;
            transition: fill 100ms ease;
        }

        .pwa-nav__icon {
            vertical-align: top;
            width: 28px;
            height: 28px;
            opacity: 70%;
            transition: opacity 100ms ease;
        }

        .pwa-nav__label {
            margin-top: 5px;
            font-size: 10px;
            line-height: 14px;
        }


.pwa-nav__link--current {
    color: var(--brand);
}
    .pwa-nav__link--current::before {
        background-color: currentColor;
    }

    .pwa-nav__link--current .pwa-nav__symbol {

    }

    .pwa-nav__link--current .pwa-nav__icon {
        opacity: 100%;
    }
