.tabs-alike-nav {
    display: flex;
    align-items: flex-start;
    overflow-x: auto;
    margin-left: calc(-1 * var(--container-padding));
    margin-right: calc(-1 * var(--container-padding));
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
    -webkit-overflow-scrolling: touch;
}
    .tabs-alike-nav__ribbon {
        display: inline-flex;
        align-items: center;
    }
        .tabs-alike-nav__link {
            display: block;
            padding: 14px 8px;
            border: none;
            background-color: transparent;
            font-family: inherit;
            text-decoration: none;
            cursor: pointer;
        }
            .tabs-alike-nav__inner {
                display: block;
                padding: 6px 16px;
                border-radius: 6px;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                white-space: nowrap;
            }

        .tabs-alike-nav__link--current {

        }
            .tabs-alike-nav__link--current .tabs-alike-nav__inner {
                background-color: var(--brand);
                color: #FFFFFF;
            }

            .tabs-alike-nav__link:not(.tabs-alike-nav__link--current) .tabs-alike-nav__inner {
                background-color: transparent !important;
            }
