:root {
    --brand: #F87C56;
    --primary-colour: #F87C56;
    --primary-colour-hover: #FF8B5B;
    --gradient-colour-1: #DA4509;
    --gradient-colour-2: #F8BF78;
    --font: 'Nunito Sans', sans-serif;
    --light: 300;
    --extra-bold: 800;
    --black: 900; /* font weight, not color */
    --tooltip-background: rgb(0 0 0 / 90%);

    --ease-out-quint: cubic-bezier(0.22, 1, 0.36, 1);
    --transition-hover: 100ms ease;
    --transition-dropdown: 200ms ease;
    --transition-show: 300ms var(--ease-out-quint);

    --card-radius: 15px;
    --input-radius: 10px;

    --image-fallback: #F7F6FB;
    --input-border: #5D6164;
    --input-border-hover: var(--primary-colour);
    --placeholder-color: #5D6164;
    --disabled-background: #F6F6F6;
    --disabled-color: #5D6164;
    --success: #2ECC71;
    --danger: #FF0033;

    --grey: #B7BDC2;
    --dark-grey: #5D6164;
    --hover: rgb(183 189 194 / 30%); /* good for small elements such as buttons */
    --hover-lighter: rgb(183 189 194 / 15%); /* good for big elements such as dropdown menu items */
    --active: rgb(183 189 194 / 45%);
    --white-hover: #FAFAFA;
}
