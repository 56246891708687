.membership-card {
    box-shadow: var(--shadow-over-grey-background);
    border-radius: var(--card-radius);
}
    .membership-card__body {
        padding: 9px 12px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: var(--brand);
        border-top-left-radius: var(--card-radius);
        border-top-right-radius: var(--card-radius);
    }
        .membership-card__qr-preview {
            padding: 5px;
            background-color: #FFFFFF;
            border-radius: 15px;
            width: fit-content;
            margin-left: auto;
        }
            .membership-card__qr-code-pic {
                vertical-align: top;
                width: 56px;
                height: 56px;
            }

    .membership-card__footer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: #FFFFFF;
        border-bottom-left-radius: var(--card-radius);
        border-bottom-right-radius: var(--card-radius);
    }
        .membership-card__name {
            padding: 10px 24px 9px 12px;
            font-size: 18px;
            line-height: 23px;
            text-decoration: none;
            color: inherit;
        }

        .membership-card__total-vouchers {
            padding: 13px 12px 9px 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            font-size: 18px;
            line-height: 21px;
            text-decoration: none;
            color: inherit;
        }
            .membership-card__voucher-icon {
                margin-bottom: 5px;
            }
